export const API_KEY = "-*I/H)wvMN7LaEUwh;fFb;~M\\7rb`8^|Ci~3HS+5SF=zP|f`";
export const API_URL = "https://api.easyrentor.com/api/v1";
// export const API_KEY = "owner-mock-key";
// export const API_URL = "http://localhost:8080/api/v1";

export const DAMAGE_TYPES = ["SCRATCH", "SPOT", "OTHER"];

// STATUSES
export const ANNOTATION_STATUS_DRAFT = "DRAFT";
export const ANNOTATION_STATUS_READY = "READY";
export const ANNOTATION_STATUS_PENDING = "PENDING";
export const ANNOTATION_STATUS_SUCCESS = "SUCCESS";
export const ANNOTATION_STATUS_FAILED = "FAILED";

// Redux Annotations actions
export const ACTION_SET_STATIC_ANNOTATIONS = "ACTION_SET_STATIC_ANNOTATIONS";
export const ACTION_ADD_ANNOTATION = "ACTION_ADD_ANNOTATION";
export const ACTION_UPDATE_ANNOTATION = "ACTION_UPDATE_ANNOTATION";
export const ACTION_REMOVE_ANNOTATION = "ACTION_REMOVE_ANNOTATIONS";
export const ACTION_RESET_ALL_ANNOTATIONS = "ACTION_RESET_ALL_ANNOTATIONS";
export const ACTION_RESET_STATIC_ANNOTATIONS =
    "ACTION_RESET_STATIC_ANNOTATIONS";
export const ACTION_RESET_DYNAMIC_ANNOTATIONS =
    "ACTION_RESET_DYNAMIC_ANNOTATIONS";
export const ACTION_SET_DRAWING_DAMAGE_TYPE = "ACTION_SET_DRAWING_DAMAGE_TYPE";
export const ACTION_UPDATE_FILTER_ANNOTATION =
    "ACTION_UPDATE_FILTER_ANNOTATION";
export const ACTION_SET_ANNOTATION_STATUS = "ACTION_SET_ANNOTATION_STATUS";
export const ACTION_SET_MULTIPLE_ANNOTATIONS_STATUSES =
    "ACTION_SET_MULTIPLE_ANNOTATIONS_STATUSES";

// damage type filter only
export const ACTION_TOGGLE_DAMAGE_TYPE_FILTER =
    "ACTION_TOGGLE_DAMAGE_TYPE_FILTER";
export const ACTION_SET_DAMAGE_TYPE_FILTERS = "ACTION_SET_DAMAGE_TYPE_FILTERS";

// OPERATIONS
export const SET_OPERATION_MODE = "SET_OPERATION_MODE";
export const OPERATION_MODE_IDLE = "OPERATION_MODE_IDLE";
export const OPERATION_MODE_SUBMIT_ANNOTATIONS =
    "OPERATION_MODE_SUBMIT_ANNOTATIONS";

// USERS
export const ACTION_USER_LOGIN = "ACTION_USER_LOGIN";
export const ACTION_USER_LOGOUT = "ACTION_USER_LOGOUT";
